<template>
    <div class="checkAction">
        <div class="screenAction-content">
            <div class="group">
                <div class="group-label">任务名称</div>
                <a-input class="group-item" v-model="Name" placeholder="请输入任务名称" />
            </div>
            <div class="group">
                <div class="group-label">任务标识</div>
                <a-input class="group-item" v-model="Label" placeholder="请输入任务标识" />
            </div>
            <div class="group">
                <div class="group-label">地址Url</div>
                <a-input class="group-item" v-model="Url" placeholder="请输入地址Url" />
            </div>
            <div class="group">
                <div class="group-label">备注</div>
                <a-textarea
                    class="group-item" v-model="Remarks" placeholder="请输入备注,例如检查说明，查询语句。处理方法"
                />
            </div>
        </div>
        <div
            :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px'
      }"
        >
            <a-button style="marginRight: 8px" @click="onClose">
                取消
            </a-button>
            <a-button type="primary" @click="_action()">
                确定
            </a-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "checkAction",
    data(){
        return{
            Name:'',
            Label:'',
            Url:'',
            Remarks:'',

        }
    },
    props:{
        Id:{
            type:String,
        }
    },
    watch:{
        Id: {//深度监听，可监听到对象、数组的变化
            handler(val, oldVal) {
                if(val){
                    this.loadData();
                }else{
                    this._empty();
                }
            },
            deep: true //true 深度监听
        },
    },
    created() {
        if(this.Id && typeof(this.Id) !='undefined'){
            this.loadData();
        }
    },
    methods:{
        loadData(){
            let self = this;
            this.$axios.get(1292259,{Id:self.Id},res=>{
                if(res.data.code==1){
                    let infoData = res.data.data;
                    self.Name  = infoData.Name;
                    self.Label = infoData.Label;
                    self.Url   = infoData.Url;
                    self.Remarks = infoData.Remarks;

                }else{
                    this.$message.error(res.data.msg);
                }
            })
        },
        _action(){
            let self = this;
            if(!this.Name){
                this.$message.error('请输入名称');
                return false;
            }
            if(!this.Label){
                this.$message.error('请输入标识');
                return false;
            }
            if(!this.Url){
                this.$message.error('请输入地址');
                return  false;
            }
            this.$message.loading('操作中...',0);
            this.$axios.get(1292257,{
                Name:self.Name,
                Label:self.Label,
                Url:self.Url,
                Remarks:self.Remarks,
                Id:self.Id || '',
            },res=>{
                this.$message.destroy();
                if(res.data.code==1){

                    this.$message.success(res.data.msg);
                    self.onClose();
                }else{
                    this.$message.error(res.data.msg);
                }
            })
        },
        _empty(){
            this.Name = '';
            this.Url = '';
            this.Label ='';
            this.Remarks = '';
        },
        onClose() {
            this._empty()
            this.$emit('visible', false)
        },
    }
}
</script>

<style scoped lang="less">
.screenAction-content{
    width: 80%;
    .group{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .group-label{
            width: 80px;
            text-align: left;
        }
        .group-item{
            flex:1;
        }

    }
}

</style>