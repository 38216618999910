var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkAction"},[_c('div',{staticClass:"screenAction-content"},[_c('div',{staticClass:"group"},[_c('div',{staticClass:"group-label"},[_vm._v("任务名称")]),_c('a-input',{staticClass:"group-item",attrs:{"placeholder":"请输入任务名称"},model:{value:(_vm.Name),callback:function ($$v) {_vm.Name=$$v},expression:"Name"}})],1),_c('div',{staticClass:"group"},[_c('div',{staticClass:"group-label"},[_vm._v("任务标识")]),_c('a-input',{staticClass:"group-item",attrs:{"placeholder":"请输入任务标识"},model:{value:(_vm.Label),callback:function ($$v) {_vm.Label=$$v},expression:"Label"}})],1),_c('div',{staticClass:"group"},[_c('div',{staticClass:"group-label"},[_vm._v("地址Url")]),_c('a-input',{staticClass:"group-item",attrs:{"placeholder":"请输入地址Url"},model:{value:(_vm.Url),callback:function ($$v) {_vm.Url=$$v},expression:"Url"}})],1),_c('div',{staticClass:"group"},[_c('div',{staticClass:"group-label"},[_vm._v("备注")]),_c('a-textarea',{staticClass:"group-item",attrs:{"placeholder":"请输入备注,例如检查说明，查询语句。处理方法"},model:{value:(_vm.Remarks),callback:function ($$v) {_vm.Remarks=$$v},expression:"Remarks"}})],1)]),_c('div',{style:({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #e8e8e8',
    padding: '10px 16px',
    textAlign: 'left',
    left: 0,
    background: '#fff',
    borderRadius: '0 0 4px 4px'
  })},[_c('a-button',{staticStyle:{"marginRight":"8px"},on:{"click":_vm.onClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm._action()}}},[_vm._v(" 确定 ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }