<template>
    <div class="checkData">
        <div class="action">
            <div  class="search">
                <div class="search-list" @click="() => searchModal(true)">
                    <div class="search-list-info">
                        <i class="iconfont icon-a-ziyuan5 search-list-icon"
                           :style="!isSearch()?'color: #2994FF;':''"></i>
                        <div class="search-list-text" @click.stop="closeSearch()"
                             :style="!isSearch()?'color: #2994FF;z-index:999;':''">
                            {{ isSearch() ? '筛选' : '取消筛选' }}
                        </div>
                        <i class="iconfont icon-xiangxia1 search-list-down"></i>
                    </div>
                    <div class="search-model" v-if="modalVisible">
                        <div class="search-group">
                            <div class="search-label">名称：</div>
                            <a-input style="width: 100%" placeholder="请输入名称" v-model="Name"/>
                        </div>
                        <div class="search-group">
                            <div class="search-label">标识：</div>
                            <a-input style="width: 100%" placeholder="请输入标识" v-model="Label"/>
                        </div>
                        <div class="search-group">
                            <div class="search-label">Url：</div>
                            <a-input style="width: 100%" placeholder="请输入Url" v-model="Url"/>
                        </div>
                        <div class="search-group">
                            <div class="search-label">录入人：</div>
                            <div class="search-group-item">
                                <person
                                    class="item"
                                    v-bind="personObj"
                                    @getData="ChangeSearchUser($event, 'CreateUserId')"
                                ></person>
                            </div>
                        </div>
                        <div class="search-bottom">
                            <a-button @click.stop="toSearch" type="primary" class="search-bottom-bth">确定</a-button>
                            <a-button @click.stop="() => searchModal(false)" class="search-bottom-bth">取消</a-button>

                        </div>
                    </div>
                </div>


            </div>
            <a-button @click="() => _screenAction(true)" type="primary" class="search-bottom-bth">添加</a-button>
        </div>




<!--        列表-->
        <div class="table">
            <div class="table-list" v-for="(item,index) in tableList" @mouseover="changeIndex(index)" @mouseout="_emptyKey" :class="listIndex ==index ?'table-list-index':''">
                <div class="table-group">
                    <div class="table-title">{{item.Name}}</div>
                    <a-dropdown v-show="listIndex ==index ">
                        <a-menu slot="overlay" @click="handleMenuClick($event,item.Id)">
                            <a-menu-item key="1">编辑 </a-menu-item>
                            <a-menu-item key="2">删除</a-menu-item>
                        </a-menu>
                        <a-icon  type="dash" />
                    </a-dropdown>
                </div>
                <div class="ResultCount">{{item.ResultCount?item.ResultCount:0}}</div>
<!--                <div class="table-group">-->
<!--                    <div class="CreateTime">{{item.CreateTime}}</div>-->
<!--                    <a-avatar :src="'http://sk-company-file.sikegroup.com/avatar/'+item.CreateUserId" type="user" :size="36"></a-avatar>-->
<!--                    <div class="CreateName">{{item.CreateUserName}}</div>-->
<!--                </div>-->

            </div>
        </div>
        <a-drawer
            title="添加"
            width="1000"
            :closable="false"
            :visible="visible"
            @close="() => _screenAction(false)"
        >
            <div style="margin-bottom: 60px">
                <checkAction :Id="Id" @visible="() => _screenAction(false)"></checkAction>
            </div>
        </a-drawer>
    </div>


</template>

<script>
import checkAction from 'pages/System/checkAction'
import person from '../../components/SelectPersonnel/SelectPersonnel'
export default {
    name: "checkData",
    components: {checkAction,person},
    data() {
        return {
            visible: false,
            Page: 0,
            Limit: 21,
            Name: '',
            Label: '',
            Url: '',
            CreateUserId: '',
            tableList: [],
            total: 0,
            Id:'',
            personObj: {
                rank: 0, //职级 0为不选，1为选
                nums: 1, //选的数量
                department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                contacts: 'recent112', //常用联系人 不要常用联系人传空''
                selectArr: [] //要传入的数据
            },
            modalVisible:false,
            CreateUserIdTitle:'',
            listIndex:-1,
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        changeIndex(val){
            this.listIndex = val;
        },
        _emptyKey(){
            this.listIndex = -1;
        },
        searchModal(val){
            if(val){
                if(this.CreateUserId){
                    this.personObj.selectArr = [];
                    let arr = {};
                    arr.id = this.CreateUserId;
                    arr.title = this.CreateUserIdTitle;
                    this.personObj.selectArr.push(arr);
                }
            }
            this.modalVisible = val;
        },
        toSearch(){
            this.Page = 0;
            this.loadData();
            this.modalVisible = false;
        },
        isSearch(){
            if(this.Name || this.Url || this.Label || this.CreateUserId){
                return false;
            }else{
                return  true;
            }
        },
        closeSearch(){
            this.Name ='';
            this.Label= '';
            this.CreateUserId= '';
            this.Url= '';
            this.CreateUserIdTitle= '';
            this.personObj.selectArr = [];
            this.modalVisible = false;
            this.Page = 0;
            this.loadData();
        },
        ChangeSearchUser(val, name) {
            if (val.length > 0) {
                if (name == 'CreateUserId') {
                    this.CreateUserId = val[0].id;
                    this.CreateUserIdTitle = val[0].title;
                }

            }
        },
        loadData() {
            let self = this;
            let data ={
                Page:self.Page,
                Limit:self.Limit,
                Name:self.Name,
                Label:self.Label,
                Url:self.Url,
                CreateUserId:self.CreateUserId
            };
            this.$axios.get(1292256, data, res => {
                if (res.data.code == 1) {
                    self.tableList = res.data.data;
                    self.total = res.data.count;
                } else {
                    self.total = 0;
                    self.tableList = [];
                    this.$message.error(res.data.msg);
                }
            })
        },
        handleMenuClick(val,id){
            this.Id = id;
            if(val.key==2){
                let self =  this;
                this.$message.loading('操作中...',0);
                this.$axios.get(1292258,{
                    Id:id
                },res=>{
                    this.$message.destroy();
                    if(res.data.code==1){
                        self.loadData();
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            }else{
                this.visible = true;
            }
        },
        _screenAction(val) {
            this.visible = val;
            if(!val){
                this.Id = '';
            }
            this.loadData();
        },
    }
}
</script>

<style scoped lang="less">
.checkData {
    min-height: 500px;
    background: #FFFFFF;
    padding: 20px;
}
.table{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 35px;
    .table-list{
        width: 250px;
        padding: 20px;
        background:rgba(255,255,255,1);
        box-shadow:0px 3px 4px rgba(14,25,36,0.09);
        border-radius:4px;
        margin-right: 30px;
        margin-bottom: 30px;
        cursor: pointer;
        .table-group{
            display:flex;
            align-items: center;
            margin-bottom: 15px;
            .table-title{
                color: #666;
                font-size: 16px;
                flex:1;
                overflow-x: hidden;
                margin-right: 8px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .CreateTime{
                color: #999;
                font-size: 12px;
                flex: 1;
                margin-right: 8px;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .CreateName{
                color: #999;
                font-size: 12px;
                margin-left: 8px;
            }
        }
        .ResultCount{
            font-size: 28px;
            text-align: center;
            font-weight: bold;
            margin-bottom: 8px;
        }
        .table-group:last-child{
            margin-bottom: 0px;
        }
    }
    .table-list:hover{
        border: 1px solid #2894ff;
    }
}
.action{
    display: flex;
    align-items: center;
}
.search {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 12px;
    //margin-bottom: 20px;
    //margin-top: 20px;

    .search-list {
        position: relative;
        min-width: 138px;
        margin-right: 8px;
        margin-left: 8px;
        border: 1px solid #E8E9EE;
        height: 36px;
        border-radius: 4px;

        .search-model {
            position: absolute;
            left: 0;
            top: 46px;
            width: 500px;
            background: #FFFFFF;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 5px;
            z-index: 1000;
            padding: 15px 18px 0px 18px;

            .search-group {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                .search-label {
                    color: #A8A9AA;
                    font-size: 12px;
                    margin-right: 6px;
                    min-width: 82px;
                }

                .search-group-item {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .search-bottom {
                display: flex;
                height: 53px;
                background: #F9F9F9;
                border-radius: 0px 0px 5px 5px;
                align-items: center;
                width: 100%;
                padding-right: 20px;
                text-align: right;
                flex-direction: row-reverse;

                .search-bottom-bth {
                    margin-left: 8px;
                }
            }
        }

        .search-list-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 0px 8px;

            .search-list-icon {
                color: #999999;
            }

            .search-list-text {
                margin: 0px 5px;
                display: flex;
                align-items: center;
                cursor: pointer;

                .tree-close {
                    color: #999999;
                    margin-left: 5px;
                    z-index: 1000;
                    font-size: 12px;
                }
            }

            .search-list-down {
                color: #999999;
            }
        }

        .search-tree {
            width: 100%;
            opacity: 0;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 100;
        }
    }

}
</style>