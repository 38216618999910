import { render, staticRenderFns } from "./checkAction.vue?vue&type=template&id=44204416&scoped=true&"
import script from "./checkAction.vue?vue&type=script&lang=js&"
export * from "./checkAction.vue?vue&type=script&lang=js&"
import style0 from "./checkAction.vue?vue&type=style&index=0&id=44204416&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44204416",
  null
  
)

export default component.exports